<template>
  <section
    v-if="!hasOrder"
    id="descontos-especiais"
    v-auto-animate
    class="relative mt-32 bg-special-secondary px-12 pb-14 pt-40 lg:pt-14"
  >
    <div class="mx-auto flex flex-col lg:w-[808px]">
      <figure
        class="absolute -top-12 left-1/2 -translate-x-1/2 transform lg:relative lg:left-auto lg:top-0 lg:mx-auto lg:mb-12 lg:transform-none"
      >
        <img :src="content.image.path.desktop" :width="content.image.width" :alt="content.image.alt" />
      </figure>
      <hgroup class="mb-14 text-center text-black">
        <h2 class="mb-8 text-4xl font-bold leading-tight lg:text-6xl lg:leading-4xl" v-html="content.title"></h2>
        <h3 class="text-lg lg:text-xl" v-html="content.subtitle"></h3>
      </hgroup>
      <div class="mx-auto max-w-[253px] lg:max-w-none">
        <ul v-if="isMobile" class="justify- mb-9 flex items-baseline gap-[8px] self-baseline align-baseline lg:hidden">
          <li
            v-for="item in content.promo.items"
            :key="item"
            class="flex w-full flex-col rounded-xl border border-neutral-light8 text-center transition-colors duration-300"
            :class="[
              item.cta.highlight ? 'p-3' : 'p-3 mt-auto',
              item.id === couponSelected ? 'bg-primary-light text-white' : 'bg-white text-black',
            ]"
          >
            <RadioButton
              v-model="couponSelected"
              :inputId="item.id"
              name="coupon"
              :value="item.id"
              class="hidden overflow-hidden"
            />
            <label :for="item.id">
              <strong class="text-5xl leading-9">{{ item.cta.title }}</strong>
              <p class="text-xl">{{ item.cta.subtitle }}</p>
            </label>
          </li>
        </ul>
        <ul class="mb-14 gap-[35px] lg:flex">
          <li
            v-if="couponSelected === null && isMobile"
            class="flex flex-col items-center rounded-xl border border-neutral-light8 bg-white p-7 text-center"
          >
            <h4
              class="text-4xl font-extrabold leading-tight lg:text-[36px] lg:leading-[43px]"
              v-html="content.promo.placeholder.title"
            ></h4>
            <img
              :src="content.promo.placeholder.image.path.desktop"
              :alt="content.promo.placeholder.image.alt"
              :width="content.promo.placeholder.image.width"
              class="my-6"
            />
            <p class="text-lg font-normal">
              {{ content.promo.placeholder.disclaimer }}
            </p>
          </li>
          <template v-else>
            <li
              v-for="item in content.promo.items"
              :key="item"
              :class="[
                !isMobile || (couponSelected === item.id && isMobile) ? 'block' : 'hidden',
                item.id === couponSelected && !isMobile ? 'border-primary-light bg-[#FEDDC7]' : 'bg-white',
              ]"
              class="relative mt-auto w-[243px] rounded-xl border border-neutral-light8 transition-all duration-300 hover:bg-[#FEDDC7]"
            >
              <RadioButton
                v-if="!isMobile"
                v-model="couponSelected"
                :inputId="item.id"
                name="coupon"
                :value="item.id"
                class="absolute left-6 top-6 hidden"
                sele
              />
              <label :for="item.id" class="flex h-full cursor-pointer flex-col items-center p-6 text-center lg:px-0">
                <span :class="`bg-special-medal-${item.id}`" class="rounded-full px-4 py-1 text-lg text-white">
                  {{ item.content.tag.title }}
                </span>
                <h4
                  class="my-2 text-[36px] font-extrabold leading-[43px] tracking-[0.01em]"
                  v-html="item.content.title"
                ></h4>
                <img
                  :src="item.content.image.path.desktop"
                  :alt="item.content.image.alt"
                  :width="item.content.image.width"
                  class="my-6"
                />
                <p class="text-nowrap text-lg text-neutral-gray6" v-html="item.content.disclaimer"></p>
              </label>
            </li>
          </template>
        </ul>
      </div>
      <div v-auto-animate>
        <div
          v-if="!isMobile || (isMobile && couponSelected != null)"
          class="mx-auto flex max-w-[358px] flex-col items-center rounded-md bg-white px-7 py-8 text-center lg:w-full lg:max-w-none lg:flex-row lg:py-6"
        >
          <p class="mb-6 lg:mb-0 lg:ml-auto lg:text-lg" v-html="content.discount.title"></p>
          <div class="relative mx-auto grow-0 lg:ml-7 lg:mr-auto">
            <button
              :title="coupon.title"
              class="relative z-10 rounded border-2 border-dashed border-black bg-[#FFFFFF59] px-16 py-2 lg:min-w-[136px] lg:max-w-[136px] lg:px-0"
              @click="copyCouponToClipboard"
            >
              <i class="uil uil-copy mr-4 text-[#abaac1]"> </i>
              <span v-if="!copying">
                {{ coupon.code }}
              </span>
              <span v-else> COPIADO! </span>
            </button>
            <template v-if="copying">
              <span
                class="absolute left-0 inline-flex h-full w-full animate-ping-slow rounded-full opacity-75"
                :class="coupon.class"
              ></span>
            </template>
          </div>
        </div>
      </div>
      <div class="mt-20 flex border-t border-white text-center text-black">
        <p class="leading-[14px ] mt-4 lg:leading-[22px]" v-html="content.disclaimer.title"></p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStore } from "@nanostores/vue";
import { userHasOrder } from "@store/store.ts";
import { computed, onMounted, reactive, ref, watch } from "vue";

import useIsMobile from "@/hooks/useIsMobile";

const { isMobile } = useIsMobile();
const hasOrder = useStore(userHasOrder);

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});
const copying = ref(false);
const content = computed(() => props.content);
const coupon = reactive({ id: "", code: "", title: "", class: "" });
const couponSelected = ref(null);

function copyCouponToClipboard() {
  copying.value = true;
  setTimeout(() => (copying.value = false), 2000);
  navigator.clipboard.writeText(coupon.code);
}

onMounted(() => {
  couponSelected.value = "gold";
});

watch(couponSelected, (newValue, oldValue) => {
  content.value.promo.items.map((c) => {
    if (c.id === newValue) {
      coupon.id = c.id;
      coupon.code = c.content.coupon.code;
      coupon.title = c.content.coupon.title;
      coupon.class = `bg-special-medal-${c.id}`;
    }
  });
});
</script>

<style lang="scss" scoped>
#descontos-especiais {
  scroll-margin-top: 120px;
  @media screen and (min-width: 768px) {
    scroll-margin-top: 100px;
  }
}
</style>
